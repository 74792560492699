import React from "react";
import { DataColumnType, normalizeDate, TypedDataColumn } from "hcss-tables";
import { Filter, IntegratedFiltering } from "@devexpress/dx-react-grid";

declare type ColumnPredicatesByType = Record<
  DataColumnType,
  (
    value: string | number | Date,
    filter: Filter,
    column: TypedDataColumn
  ) => boolean
>;

const columnPredicatesByType: Partial<ColumnPredicatesByType> = {
  [DataColumnType.Date]: (value, filter, column) => {
    if (!value || !filter.value) {
      return false;
    }

    const dateValue = normalizeDate(
      value.toString(),
      column.config && column.config.utc,
      column.config && column.config.ignoreTimezone
    );
    const searchValue = filter.value.toLowerCase().trim();

    return (
      dateValue.isValid() &&
      dateValue.format("L").toLowerCase().includes(searchValue)
    );
  },
  [DataColumnType.DateTime]: (value, filter, column) => {
    if (!value || !filter.value) {
      return false;
    }

    const dateValue = normalizeDate(
      value.toString(),
      column.config && column.config.utc,
      column.config && column.config.ignoreTimezone
    );
    const searchValue = filter.value.toLowerCase().trim();

    return (
      dateValue.isValid() &&
      dateValue.format("L LT").toLowerCase().includes(searchValue)
    );
  },
  // the following logic for (Links, CheckList, Custom) was copied from the filtering-search.tsx in the hcss-tables
  [DataColumnType.Links]: (value, filter) => {
    if (!filter.value) return false;
    return (
      linkToString(value).toUpperCase().indexOf(filter.value.toUpperCase()) > -1
    );
  },
  [DataColumnType.CheckList]: (value, filter) => {
    if (!filter.value) return false;
    return (
      checkListToString(value)
        .toUpperCase()
        .indexOf(filter.value.toUpperCase()) > -1
    );
  },

  [DataColumnType.Custom]: (value, filter, column) => {
    if (!column.config || !column.config.valueAsString || !filter.value)
      return false;
    return (
      column.config
        .valueAsString(value)
        .toUpperCase()
        .indexOf(filter.value.toUpperCase()) > -1
    );
  }
} as const;

export const getColumnsExtensions = (
  type: DataColumnType,
  columns: TypedDataColumn[]
): IntegratedFiltering.ColumnExtension[] => {
  const predicateByType = columnPredicatesByType[type];

  if (!predicateByType) {
    return [];
  }

  return columns
    .filter(c => c.type === type)
    .map(c => {
      return {
        columnName: c.name,
        predicate: (value, filter) => predicateByType(value, filter, c)
      };
    });
};

const extensionsDataTypes = Object.keys(columnPredicatesByType)
  .map(key => +key as DataColumnType)
  .filter(key => !!key);

export interface ExtendedIntegratedFilteringProps {
  columns: TypedDataColumn[];
}

export const ExtendedIntegratedFiltering = React.memo(
  ({ columns }: ExtendedIntegratedFilteringProps) => {
    const filterExtensions = extensionsDataTypes.reduce<
      IntegratedFiltering.ColumnExtension[]
    >(
      (extensions, type) => [
        ...extensions,
        ...getColumnsExtensions(type, columns)
      ],
      []
    );
    return <IntegratedFiltering columnExtensions={filterExtensions} />;
  }
);

const linkToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((link: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += link.description || link.url;
    });
  }
  return retString;
};

const checkListToString = (value: any) => {
  let retString = "";
  if (value) {
    value.forEach((list: any, index: number) => {
      retString += index > 0 ? ", " : "";
      retString += list.checked ? "Checked " : "Not Checked ";
      retString += list.description;
    });
  }
  return retString;
};
