import {
  Address,
  CreateVendorContactDto,
  IContactDto,
  ICreateVendorDto,
  ICreateVendorMinorityStatusDto,
  IUpdateVendorContactDto,
  IUpdateVendorMinorityStatusDto,
  MinorityStatusDto,
  IVendorDtoV1Response,
  IMinorityTypeDto,
  ICreateMinorityTypeDto,
  IUpdateMinorityTypeDto,
  IContactProduct,
  IProductTypeDto,
  IUpdateProductTypeDto,
  ICreateProductTypeDto,
  ProductTypeDto,
  CompanyTypeDto,
  MinorityTypeDto
} from "./../../api/GeneratedClients/ContactsClient";
import {
  getSelectedBusinessUnitIdSaga,
  getTokenSaga
} from "api/api-saga-factory";
import ContactsApi from "api/ContactApi";
import { actions } from "modules/contacts";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import axios, { AxiosInstance } from "axios";
import { notify } from "hcss-components";
import { strings } from "localization";
import { ExportFileType, isNullOrWhitespace } from "core";
import { CreateCompanyMode } from "./components/vendor-list-modal";
import { MoveContactSuccessNotification } from "./components/move-contact-modal";
import { contactProductsListFactory } from "./helpers/contactProductsListFactory";
import { newProductDtoFactory } from "./helpers/newProductDtoFactory";
import { separateNewAndExistingProducts } from "./helpers/separateNewAndExistingProducts";

class PreconContactsApi {
  instance: AxiosInstance;
  businessUnitId: string;

  constructor(accessToken: string, businessUnitId: string) {
    this.instance = axios.create();
    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.businessUnitId = businessUnitId;
  }

  updateVendor = (
    vendorDto: IVendorDtoV1Response
  ): Promise<IVendorDtoV1Response> => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/contacts/updateVendor`;
    return this.instance
      .post<IVendorDtoV1Response>(url, vendorDto)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
  updateContactProducts = (contact: IContactDto) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/contacts/updateContactProducts`;
    return this.instance
      .post(url, contact)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
  moveContactProducts = (
    contact: IContactDto,
    newVendorId: string,
    oldContactProducts: IContactProduct[],
    newContactProducts: IContactProduct[]
  ) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/contacts/moveContactProducts`;
    const newProducts = newContactProducts;
    const oldProducts = oldContactProducts;
    return this.instance
      .post(
        url,
        { contact: contact, newProducts, oldProducts },
        {
          params: { newVendorId: newVendorId }
        }
      )
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
  syncContactToProjects = (contact: IContactDto) => {
    const url = `/api/v1/businessUnits/${this.businessUnitId}/contacts/syncContactToProjects`;
    return this.instance
      .post(url, null, {
        params: {
          contactId: contact.id
        }
      })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };
}

function* loadContacts() {
  yield all([
    call(loadVendors),
    call(loadMinorityTypes),
    call(loadProductTypes),
    call(loadCompanyTypes)
  ]);
  yield put(actions.loadContactsState.success());
}

function* loadVendors() {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const vendorList = yield call(api.getDetailedVendors);
    yield put(actions.loadVendorsState.success(vendorList));
  } catch (error) {
    console.error(error);
    yield put(actions.loadVendorsState.failure(error));
  }
}

function* loadMinorityTypes() {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const minorityTypes = yield call(api.getMinorityTypes);
    yield put(actions.loadMinorityTypes.success(minorityTypes));
  } catch (error) {
    console.error(error);
    yield put(actions.loadMinorityTypes.failure(error));
  }
}

function* loadProductTypes() {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const productTypes = yield call(api.getProductTypes);
    yield put(actions.loadProductTypes.success(productTypes));
  } catch (error) {
    console.error(error);
    yield put(actions.loadProductTypes.failure(error));
  }
}

function* loadCompanyTypes() {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const companyTypes = yield call(api.getCompanyTypes);
    yield put(actions.loadCompanyTypes.success(companyTypes));
  } catch (error) {
    console.error(error);
    yield put(actions.loadCompanyTypes.failure(error));
  }
}

function* loadVendorsReport(
  action: ActionType<typeof actions.loadVendorsReport.request>
) {
  try {
    const fileSaver = action.payload.fileSaver;
    const reportType = action.payload.reportType;
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);

    if (reportType === ExportFileType.Csv) {
      const csvData = yield call(api.getVendorsCsvReport);
      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8"
      });
      fileSaver.saveAs(
        blob,
        strings.formatString(
          strings.tables.export.csv.filename,
          "VendorContacts",
          new Date().toISOString()
        ) as string
      );
    } else {
      const excelData = yield call(api.getVendorsExcelReport);
      fileSaver.saveAs(
        excelData,
        strings.formatString(
          strings.tables.export.excel.filename,
          "VendorContacts",
          new Date().toISOString()
        ) as string
      );
    }
    yield put(actions.loadVendorsReport.success());
  } catch (error) {
    console.error(error);
    yield put(actions.loadVendorsReport.failure(error));
  }
}

function* createVendor(
  action: ActionType<typeof actions.createVendor.request>
) {
  const { vendor: v, history, mode } = action.payload;
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    // we need to cast these as Address instances
    // because the API needs a .toJSON function for its nested
    // children classes. Not sure if this is the best place to do
    const primaryAddress = new Address({ ...v.primaryAddress });
    const alternateAddress = new Address({ ...v.alternateAddress });
    const cv: ICreateVendorDto = {
      ...v,
      primaryAddress,
      alternateAddress,
      companyType: v.type
    };

    const id = yield call(api.createVendor, cv);
    if (id) {
      const newVendor = yield call(api.getVendor, id);
      yield put(actions.createVendor.success(newVendor));
      yield put(actions.setShowModal(false));
      notify("success", `'${v.code}' Added!`);
      if (mode === CreateCompanyMode.Redirect)
        history.push(`/contacts/${id}/edit`);
    }
  } catch (error) {
    console.error(error);
    if (error.response.status === 409) {
      notify(
        "danger",
        `Company with Code '${action.payload.vendor.code}' already exists.`
      );
    } else {
      notify("danger", `Error updating vendor.`);
    }
    yield put(actions.setShowModal(true));
    yield put(actions.createVendor.failure(error));
  }
}

function* updateVendor(
  action: ActionType<typeof actions.updateVendor.request>
) {
  const { vendor: v, history } = action.payload;
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const preconContactsApi = new PreconContactsApi(token, businessUnitId);
    if (v.id) {
      const updatedVendor = yield call(preconContactsApi.updateVendor, v);
      const productTypes = yield call(api.getProductTypes);
      const companyTypes = yield call(api.getCompanyTypes);
      const minorityTypes = yield call(api.getMinorityTypes);

      yield put(actions.updateVendor.success(updatedVendor));
      yield put(actions.loadMinorityTypes.success(minorityTypes));
      yield put(actions.updateProductTypes.success(productTypes));
      yield put(actions.updateCompanyTypes.success(companyTypes));
      notify("success", `'${updatedVendor.code}' Saved!`);
      history.push(`/contacts/${updatedVendor.id}`);
    }
  } catch (error) {
    console.error(error);
    yield put(actions.updateVendor.failure(error));
    if (error.response.status === 409) {
      notify(
        "danger",
        `Company with Code '${v?.code.toUpperCase()}' already exists.`
      );
    } else {
      notify("danger", `Error updating vendor.`);
    }
  }
}

function* deleteVendor(
  action: ActionType<typeof actions.deleteVendor.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);

    const vendorId = action.payload;
    yield call(api.deleteVendor, vendorId);
    yield put(actions.deleteVendor.success(vendorId));
    notify("warning", "Company Removed!");
  } catch (error) {
    console.error(error);
    notify("danger", "Error deleting company.");
    yield put(actions.deleteVendor.failure(error));
  }
}

function* handleNewProductsFromContact(
  contact: IContactDto,
  vendor: IVendorDtoV1Response,
  api: ContactsApi,
  preconApi: PreconContactsApi
) {
  const { originalContactParams, newProducts } = separateNewAndExistingProducts(
    contact
  );
  if (newProducts.length > 0) {
    contact = originalContactParams;
    const existingProducts = yield call(api.getProductTypes);

    const createdProductsList = newProducts.map(newProduct => {
      const createdVendorProduct = newProductDtoFactory(
        vendor,
        existingProducts,
        newProduct
      );
      return createdVendorProduct;
    });
    vendor.products.push(...createdProductsList);

    const updatedVendor = yield call(preconApi.updateVendor, vendor);
    const [updatedProductTypes, companyTypes, minorityTypes]: [
      ProductTypeDto[],
      CompanyTypeDto[],
      MinorityTypeDto[]
    ] = yield all([
      call(api.getProductTypes),
      call(api.getCompanyTypes),
      call(api.getMinorityTypes)
    ]);

    yield put(actions.updateVendor.success(updatedVendor));
    yield put(actions.loadMinorityTypes.success(minorityTypes));
    yield put(actions.updateProductTypes.success(updatedProductTypes));
    yield put(actions.updateCompanyTypes.success(companyTypes));

    const updatedVendorProducts = updatedVendor.products;
    const newContactsProductsList: IContactProduct[] = contactProductsListFactory(
      createdProductsList,
      updatedVendorProducts
    );
    contact.products?.push(...newContactsProductsList);
  }
  return contact;
}

function* addContact(action: ActionType<typeof actions.addContact.request>) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const preconApi = new PreconContactsApi(token, businessUnitId);

    const { contact: c, vendor } = action.payload;

    if (vendor) {
      const vendorId = vendor.id;
      const completedContact: IContactDto = yield handleNewProductsFromContact(
        c,
        vendor,
        api,
        preconApi
      );

      const cvcd = new CreateVendorContactDto({
        ...completedContact,
        vendorId
      });
      const id = yield call(api.createVendorContact, vendorId, cvcd);
      if (id) {
        const contact: IContactDto = { ...completedContact, id };
        yield put(actions.setShowModal(false));
        yield put(actions.addContact.success({ vendor, contact }));
        notify("success", strings.contactManagement.contactList.saved);
      }
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.contactList.errorSaved);
    yield put(actions.addContact.failure(error));
  }
}

function* addContactFromProject(
  action: ActionType<typeof actions.addContactFromProject.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const preconApi = new PreconContactsApi(token, businessUnitId);
    const {
      contact: c,
      vendor,
      updateInput,
      setShowCreateContactModal,
      setSelectedCompany
    } = action.payload;

    if (vendor) {
      const vendorId = vendor.id;
      const completedContact = yield handleNewProductsFromContact(
        c,
        vendor,
        api,
        preconApi
      );
      const cvcd = new CreateVendorContactDto({
        vendorId,
        ...completedContact
      });
      const id = yield call(api.createVendorContact, vendorId, cvcd);
      if (id) {
        const contact: IContactDto = { ...completedContact, id };
        yield put(actions.addContactFromProject.success({ vendor, contact }));
        setShowCreateContactModal(false);
        setSelectedCompany(null);
        updateInput(contact, vendor);
        notify("success", strings.contactManagement.contactList.saved);
      }
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.contactList.errorSaved);
    yield put(actions.addContactFromProject.failure(error));
  }
}

function* updateContact(
  action: ActionType<typeof actions.updateContact.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const preconApi = new PreconContactsApi(token, businessUnitId);
    const { contact: c, vendor } = action.payload;

    if (vendor && c) {
      const vendorId = vendor.id;
      const contactId = c.id;

      const completedContact: IContactDto = yield handleNewProductsFromContact(
        c,
        vendor,
        api,
        preconApi
      );

      const updateDto: IUpdateVendorContactDto = {
        ...completedContact,
        vendorId,
        contactId
      };
      yield call(api.updateVendorContact, vendorId, contactId, updateDto);
      yield call(preconApi.updateContactProducts, completedContact);
      const contact: IContactDto = { ...completedContact };

      yield put(actions.updateContact.success({ contact, vendor }));
      notify("success", strings.contactManagement.contactList.updated);
      yield put(actions.setShowModal(false));
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.contactList.errorSaved);
    yield put(actions.updateContact.failure(error));
  }
}

function* deleteContact(
  action: ActionType<typeof actions.deleteContact.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const { contact: c, vendorId } = action.payload;
    if (vendorId && c.id) {
      yield call(api.deleteVendorContact, vendorId, c.id);
      yield put(actions.deleteContact.success({ vendorId, contact: c }));
      notify("warning", strings.contactManagement.contactList.deleted);
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.contactList.errorDeleted);
    yield put(actions.deleteContact.failure(error));
  }
}

function* moveContact(action: ActionType<typeof actions.moveContact.request>) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const preconApi = new PreconContactsApi(token, businessUnitId);

    const {
      contact,
      oldVendorId,
      oldProducts,
      newProducts,
      newVendorId,
      history
    } = action.payload;

    if (!!!(contact.id && contact.companyId && oldVendorId && newVendorId))
      return;
    const updatedContact: IUpdateVendorContactDto = {
      ...contact,
      isMainContact: false,
      vendorId: contact.companyId,
      contactId: contact.id
    };
    updatedContact.companyId = newVendorId;
    yield call(
      api.updateVendorContact,
      oldVendorId,
      contact.id,
      updatedContact,
      true
    );

    yield call(
      preconApi.moveContactProducts,
      contact,
      newVendorId,
      oldProducts,
      newProducts
    );
    yield put(
      actions.moveContact.success({
        oldVendorId: oldVendorId,
        newVendorId: newVendorId,
        contact: updatedContact
      })
    );
    notify(
      "success",
      strings.contactManagement.contactList.moveContact,
      MoveContactSuccessNotification(newVendorId, history)
    );
    yield put(actions.setShowMoveContactModal(false));
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.contactList.errorMoved);
    yield put(actions.moveContact.failure(error));
  }
}

function* addMinorityStatus(
  action: ActionType<typeof actions.addMinorityStatus.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);

    const { vendorId, minorityStatus: ms } = action.payload;
    const vms: ICreateVendorMinorityStatusDto = {
      vendorId: ms.companyId ?? "",
      minorityTypeId: ms.type?.id ?? "",
      certificationNumber: ms.certificationNumber,
      percent: ms.percent
    };

    const id = yield call(api.createVendorMinorityStatus, vendorId, vms);
    notify("success", strings.contactManagement.minorityStatusList.saved);
    const newMs = new MinorityStatusDto({
      ...ms,
      id
    });
    yield put(
      actions.addMinorityStatus.success({
        vendorId: vendorId,
        minorityStatus: newMs
      })
    );
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.minorityStatusList.errorSaved);
    yield put(actions.addMinorityStatus.failure(error));
  }
}

function* updateMinorityStatus(
  action: ActionType<typeof actions.updateMinorityStatus.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const { vendorId, minorityStatus: ms, statusId } = action.payload;

    if (vendorId && statusId) {
      const updateModel: IUpdateVendorMinorityStatusDto = {
        vendorId: ms.companyId ?? "",
        statusId: ms.id ?? "",
        minorityTypeId: ms.type?.id ?? "",
        certificationNumber: ms.certificationNumber,
        percent: ms.percent
      };
      yield call(
        api.updateVendorMinorityStatus,
        vendorId,
        statusId,
        updateModel
      );
      yield put(
        actions.updateMinorityStatus.success({
          vendorId: vendorId,
          statusId: statusId,
          minorityStatus: ms
        })
      );
      notify("success", strings.contactManagement.minorityStatusList.updated);
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.minorityStatusList.errorUpdated);
    yield put(actions.updateMinorityStatus.failure(error));
  }
}

function* deleteMinorityStatus(
  action: ActionType<typeof actions.updateMinorityStatus.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const { vendorId, minorityStatus: ms } = action.payload;

    if (vendorId && ms.id) {
      yield call(api.deleteVendorMinorityStatus, vendorId, ms.id);
      yield put(
        actions.deleteMinorityStatus.success({
          vendorId: vendorId,
          statusId: ms.id
        })
      );
      notify("success", strings.contactManagement.minorityStatusList.deleted);
    }
  } catch (error) {
    console.error(error);
    notify("danger", strings.contactManagement.minorityStatusList.errorDeleted);
    yield put(actions.updateMinorityStatus.failure(error));
  }
}

function* searchContacts(
  action: ActionType<typeof actions.searchContacts.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const searchTerm = action.payload;

    if (!isNullOrWhitespace(searchTerm)) {
      const contacts = yield call(api.searchVendorContacts, searchTerm);
      yield put(actions.searchContacts.success(contacts));
    } else yield put(actions.searchContacts.success([]));
  } catch (error) {
    console.error(error);
    yield put(actions.searchContacts.failure(error));
  }
}

function* addMinorityType(
  action: ActionType<typeof actions.addMinorityType.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const minorityType = action.payload;

    const createModel: ICreateMinorityTypeDto = {
      code: minorityType.code ?? "",
      description: minorityType.description ?? ""
    };

    const id = yield call(api.createMinorityType, createModel);
    const newMinorityType: IMinorityTypeDto = { ...minorityType, id };
    yield put(actions.addMinorityType.success(newMinorityType));
    notify(
      "success",
      strings.contactManagement.companySetup.minorityType.success.added
    );
  } catch (error) {
    console.error(error);
    if (error.response.status === 409) {
      notify(
        "danger",
        // `Minority type with code '${action.payload.code}' already exists.`
        strings.formatString(
          strings.contactManagement.companySetup.minorityType.errors.duplicated,
          action.payload.code as string
        ) as string
      );
    } else {
      notify(
        "danger",
        strings.contactManagement.minorityTypeModal.errorCreated
      );
    }
    yield put(actions.addMinorityType.failure(error));
  }
}

function* updateMinorityType(
  action: ActionType<typeof actions.updateMinorityType.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const { minorityType, minorityTypeId } = action.payload;

    const updateModel: IUpdateMinorityTypeDto = {
      id: minorityTypeId,
      code: minorityType.code ?? "",
      description: minorityType.description ?? ""
    };

    yield call(api.updateMinorityType, minorityTypeId, updateModel);
    yield put(
      actions.updateMinorityType.success({ minorityType, minorityTypeId })
    );
    notify(
      "success",
      strings.contactManagement.companySetup.minorityType.success.updated
    );
    yield put(actions.loadVendorsState.request());
  } catch (error) {
    console.error(error);
    if (error.response.status === 409) {
      notify(
        "danger",
        // `Minority type with code '${action.payload.minorityType.code}' already exists.`
        strings.formatString(
          strings.contactManagement.companySetup.minorityType.errors.duplicated,
          action.payload.minorityType.code as string
        ) as string
      );
    } else {
      notify(
        "danger",
        strings.contactManagement.minorityTypeModal.errorUpdated
      );
    }
    yield put(actions.updateMinorityType.failure(error));
  }
}

function* addProductType(
  action: ActionType<typeof actions.addProductType.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const productType = action.payload;

    const createModel: ICreateProductTypeDto = {
      code: productType.code ?? "",
      description: productType.description ?? ""
    };

    const id = yield call(api.createProductType, createModel);
    const newProductType: IProductTypeDto = { ...productType, id };
    yield put(actions.addProductType.success(newProductType));
    notify(
      "success",
      strings.contactManagement.companySetup.productType.success.added
    );
  } catch (error) {
    console.error(error);
    if (error.response.status === 409) {
      notify(
        "danger",
        strings.formatString(
          strings.contactManagement.companySetup.productType.errors.duplicated,
          action.payload.code as string
        ) as string
      );
    } else {
      notify(
        "danger",
        strings.contactManagement.companySetup.productType.errors.created
      );
    }
    yield put(actions.addProductType.failure(error));
  }
}

function* updateProductType(
  action: ActionType<typeof actions.updateProductType.request>
) {
  try {
    const token = yield call(getTokenSaga);
    const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
    const api = new ContactsApi(token, businessUnitId);
    const { productType, productTypeId } = action.payload;

    const updateModel: IUpdateProductTypeDto = {
      id: productTypeId,
      code: productType.code ?? "",
      description: productType.description ?? ""
    };

    yield call(api.updateProductType, productTypeId, updateModel);
    yield put(
      actions.updateProductType.success({ productType, productTypeId })
    );
    notify(
      "success",
      strings.contactManagement.companySetup.productType.success.updated
    );
    yield put(actions.loadVendorsState.request());
  } catch (error) {
    console.error(error);
    if (error.response.status === 409) {
      notify(
        "danger",
        // `Scope of work with code '${action.payload.productType.code}' already exists.`
        strings.formatString(
          strings.contactManagement.companySetup.productType.errors.duplicated,
          action.payload.productType.code as string
        ) as string
      );
    } else {
      notify(
        "danger",
        strings.contactManagement.companySetup.productType.errors.updated
      );
    }
    yield put(actions.addProductType.failure(error));
  }
}

function* syncContactToProjects(
  action: ActionType<typeof actions.syncContactToProjects.request>
) {
  const token = yield call(getTokenSaga);
  const businessUnitId = yield call(getSelectedBusinessUnitIdSaga);
  const preconApi = new PreconContactsApi(token, businessUnitId);
  const contactToSync = action.payload;
  try {
    yield call(preconApi.syncContactToProjects, contactToSync);
    yield put(actions.syncContactToProjects.success());
    notify("success", "Synced contact successfully");
  } catch (error) {
    yield put(actions.syncContactToProjects.failure(error as Error));

    notify("danger", "Error syncing contact");
  }
}

export const sagas = [
  takeLatest(getType(actions.loadContactsState.request), loadContacts),
  takeLatest(getType(actions.loadVendorsState.request), loadVendors),
  takeLatest(getType(actions.loadMinorityTypes.request), loadMinorityTypes),
  takeLatest(getType(actions.loadProductTypes.request), loadProductTypes),
  takeLatest(getType(actions.loadCompanyTypes.request), loadCompanyTypes),
  takeLatest(getType(actions.loadVendorsReport.request), loadVendorsReport),
  takeLatest(getType(actions.searchContacts.request), searchContacts),
  takeEvery(getType(actions.createVendor.request), createVendor),
  takeEvery(getType(actions.updateVendor.request), updateVendor),
  takeEvery(getType(actions.deleteVendor.request), deleteVendor),
  takeEvery(getType(actions.addContact.request), addContact),
  takeEvery(
    getType(actions.addContactFromProject.request),
    addContactFromProject
  ),
  takeEvery(getType(actions.updateContact.request), updateContact),
  takeEvery(getType(actions.deleteContact.request), deleteContact),
  takeEvery(getType(actions.moveContact.request), moveContact),
  takeEvery(getType(actions.addMinorityStatus.request), addMinorityStatus),
  takeEvery(
    getType(actions.updateMinorityStatus.request),
    updateMinorityStatus
  ),
  takeEvery(
    getType(actions.deleteMinorityStatus.request),
    deleteMinorityStatus
  ),
  takeEvery(getType(actions.addMinorityType.request), addMinorityType),
  takeEvery(getType(actions.updateMinorityType.request), updateMinorityType),
  takeEvery(getType(actions.addProductType.request), addProductType),
  takeEvery(getType(actions.updateProductType.request), updateProductType),
  takeEvery(
    getType(actions.syncContactToProjects.request),
    syncContactToProjects
  )
];
